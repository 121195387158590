import 'bootstrap.native/dist/bootstrap-native-v4'
import noUiSlider from 'nouislider/distribute/nouislider'
import axios from 'axios'

var debounce = require('lodash/debounce')

const bsn = require('bootstrap.native/dist/bootstrap-native-v4')

let myMarker

let userLocation = {
  btnLocation: null,
  btnSetLocation: null,
  btnSetLocation2: null,
  myLocation: null,
  modalInstance: null,
  distance: null,
  distanceSlider: null,

  init () {
    this.btnLocation = document.getElementById('btn-get-location')
    this.btnSetLocation = document.getElementById('btn-set-location')
    this.btnSetLocation2 = document.getElementById('btn-set-location2')
    this.myLocation = document.getElementById('my-location')

    // Velocity(this.btnSetLocation, 'transition.slideRightIn')

    if (!this.btnLocation) {
      return
    }

    this.setGeolocation()
    this.initDistanceSlider()
    this.getLocation()
    this.deleteLocation()

    const modalLocation = document.getElementById('locationModal')
    this.modalInstance = new bsn.Modal(modalLocation)
    this.btnSetLocation.addEventListener('click', (e) => {
      this.modalInstance.show()
    }, false)
    if (this.btnSetLocation2 !== null) {
      this.btnSetLocation2.addEventListener('click', (e) => {
        this.modalInstance.show()
      }, false)
    }
  },

  setGeolocation () {
    if (!'geolocation' in navigator) {
      this.btnLocation.parentNode.removeChild(this.btnLocation)
    }

    if ('geolocation' in navigator) {
      this.btnLocation.addEventListener('click', (e) => {
        e.preventDefault()

        const geo_options = {
          enableHighAccuracy: false,
          maximumAge: 30000,
          timeout: 27000
        }
        let wpid = navigator.geolocation.getCurrentPosition(this.geo_success, this.geo_error, geo_options)
      })
    }
  },

  initDistanceSlider () {
    let initial = true
    this.distanceSlider = document.getElementById('distance-slider')
    this.distance = document.getElementById('distance')
    this.distanceFilter = document.getElementById('client_location_distance')
    noUiSlider.create(this.distanceSlider, {
      start: 0,
      step: 1,
      connect: [true, false],
      range: {
        'min': 0,
        'max': 8
      }
    })

    if (this.distance.value > 0) {
      this.distanceSlider.noUiSlider.set(this.distance.value)
    }

    this.distanceSlider.noUiSlider.on('update', (values, handle) => {
      this.distance.value = Math.round(values[0])
      if (this.distanceFilter !== null) {
        this.distanceFilter.value = this.distance.value
      }
    })
    // WARNING: update event twice, debounce to prevent multiple ajax calls on each step
    this.distanceSlider.noUiSlider.on('update', debounce((values, handle) => {
      if (!initial) {
        this.updateDistance(Math.round(values[0]))
      }
      initial = false
    }, 300))

    this.distance.addEventListener('change', () => {
      this.distanceSlider.noUiSlider.set(this.distance.value)
      if (this.distanceFilter !== null) {
        this.distanceFilter.value = this.distance.value
      }
    })
  },

  updateDistance (value) {
    axios({
      method: 'post',
      url: '/set-distance',
      data: {
        distance: value
      }
    })
  },

  getLocation () {
    const formatted_address = sessionStorage.getItem('location.formatted_address')
    if (formatted_address) {
      this.setBtnLocationText(formatted_address)
      document.getElementById('client_location').value = formatted_address
      let locationLat = document.getElementById('client_location_lat')
      if (locationLat) {
        locationLat.value = sessionStorage.getItem('location.location_lat')
        document.getElementById('client_location_lng').value = sessionStorage.getItem('location.location_lng')
      }
    }
  },

  deleteLocation () {
    document.getElementById('delete-location').addEventListener('click', (e) => {
      e.preventDefault()
      this.modalInstance.hide()
      resetAddress()
    })
  },

  geo_success (position) {
    const geocoder = new google.maps.Geocoder()
    geocoder.geocode({
      location:
        {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
    }, function (results, status) {
      if (status === 'OK' && results[0]) {
        setAddress({
          formatted_address: results[0].formatted_address,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        })
      }
    })
  },

  geo_error (err) {
    alert('Sorry, no position available.')
    console.error(err)
  },

  setBtnLocationText (addr) {
    this.setLocationButtons('Your location is set to: ' + addr, 'Change your location', addr)
  },

  resetBtnLocationText () {
    this.setLocationButtons('Set your location ', 'Set your location ', addr)
  },

  setLocationButtons (text, text2, addr) {
    if (this.btnSetLocation !== null) {
      this.btnSetLocation.innerText = text
    }
    if (this.btnSetLocation2 !== null) {
      this.btnSetLocation2.innerText = text2
    }
    if (this.myLocation !== null) {
      this.myLocation.innerText = addr
    }
  }
}

function infoWindow (data) {
  var cntServices = Object.keys(data.services).length

  var str = '<div class="infoWindow">'
  Object.keys(data.services).forEach(function (key) {
    var location = data.services[key]
    str += '<div class="infoWindow--address">'
    str += '<strong>'
    str += location.name
    str += '</strong><br>'

    str += '<div class="row"><div class="col">'
    if (location.address || location.city) {
      str += '<div>'
      str += '<i class="fas fa-home"></i> '
      str += '<address>'
      if (location.address) {
        str += location.address + '<br>'
      }
      if (location.city) {
        str += location.postcode + ' '
        str += location.city + '<br>'
      }
      str += '</address>'

      str += '</div>'
    }

    if (location.email) {
      str += '<div>'
      str += '<i class="fas fa-envelope"></i> '
      str += location.email + '<br>'
      str += '</div>'
    }

    if (location.phone) {
      str += '<div>'
      str += '<i class="fas fa-phone"></i> '
      str += location.phone + '<br>'
      str += '</div>'
    }

    str += '</div><div class="col-md-auto">'
    str += '<a href="/service/' + location.id + '" class="btn btn-info btn-sm">View details</a>'
    str += '</div></div>'

    str += '</div>'
  })
  str += '</div>'

  return str
}

function setAddress (address) {
  document.getElementById('client_location').value = address.formatted_address
  sessionStorage.setItem('location.formatted_address', address.formatted_address)
  sessionStorage.setItem('location.location_lat', address.latitude)
  sessionStorage.setItem('location.location_lng', address.longitude)
  const lat = document.getElementById('client_location_lat')
  if (lat) {
    document.getElementById('client_location_lat').value = address.latitude
    document.getElementById('client_location_lng').value = address.longitude
  }
  userLocation.setBtnLocationText(address.formatted_address)

  axios({
    method: 'post',
    url: '/set-location',
    data: {
      lat: address.latitude,
      lng: address.longitude
    }
  })
}

function resetAddress () {
  setAddress({
    formatted_address: '',
    latitude: '',
    longitude: ''
  })
  userLocation.distance.value = 0
  userLocation.distanceSlider.noUiSlider.set(0)
  if (typeof myMarker === 'object' && 'visible' in myMarker && myMarker.visible) {
    myMarker.setVisible(false)
  }
  userLocation.resetBtnLocationText()
}

function initAutocomplete (map) {
  const input = document.getElementById('client_location')

  const autocomplete = new google.maps.places.Autocomplete(input, {
    types: ['address'],
    componentRestrictions: {
      country: ['gb']
    }
  })

  if (map) {
    myMarker = new google.maps.Marker({
      map: map,
      anchorPoint: new google.maps.Point(0, -29),
      icon: {
        url: mapOptions.icon.my,
        anchor: new google.maps.Point(23, 32),
        scaledSize: new google.maps.Size(23, 32)
      },
      title: 'Your location',
      visible: false
    })

    const myLat = sessionStorage.getItem('location.location_lat')
    const myLng = sessionStorage.getItem('location.location_lng')
    if (myLat && myLng) {
      const pos = new google.maps.LatLng(myLat, myLng)
      myMarker.setPosition(pos)
      myMarker.setVisible(true)
    }
    autocomplete.bindTo('bounds', map)

    if (myLat && myLng && typeof serviceLocation !== 'undefined') {
      if (typeof bounds === 'undefined') {
        let bounds = new google.maps.LatLngBounds()
        bounds.extend({
          lat: parseFloat(myLat),
          lng: parseFloat(myLng),
        })
        bounds.extend(serviceLocation)
        map.fitBounds(bounds)
      }
    }
  }

  autocomplete.addListener('place_changed', function () {
    if (map) {
      myMarker.setVisible(false)
    }
    let place = autocomplete.getPlace()
    if (!place.geometry) {
      window.alert('No details available for input: \'' + place.name + '\'')
      return
    }

    if (map) {
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport)
      } else {
        // map.setCenter(place.geometry.location)
        map.setZoom(13)
      }
      myMarker.setPosition(place.geometry.location)
      myMarker.setVisible(true)
    }

    setAddress({
      formatted_address: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng()
    })
  })

  input.addEventListener('blur', function (e) {
    if (e.target.value === '') {
      resetAddress()
    }
  })
}

function initMap () {
  const map = new google.maps.Map(document.getElementById('map'), {
    zoom: 12,
    center: { lat: 52.5148568, lng: -2.0776777 }
  })
  const iw = new google.maps.InfoWindow()
  const icon = {
    url: mapOptions.icon.default,
    anchor: new google.maps.Point(23, 32),
    scaledSize: new google.maps.Size(23, 32)
  }
  const icon_multiple = {
    url: mapOptions.icon.multiple,
    anchor: new google.maps.Point(23, 32),
    scaledSize: new google.maps.Size(23, 32)
  }
  let markers = []
  let bounds = new google.maps.LatLngBounds()

  Object.keys(mapData).forEach(function (key) {
    let location = mapData[key]
    let mapIcon = ('multiple' in location) ? icon_multiple : icon
    let marker = new google.maps.Marker({
      position: location.location,
      icon: mapIcon
    })
    bounds.extend(location.location)
    map.fitBounds(bounds)
    google.maps.event.addListener(marker, 'click', function (evt) {
      iw.setContent(infoWindow(location))
      iw.open(map, marker)
    })
    markers.push(marker)
  })

  const myLat = sessionStorage.getItem('location.location_lat')
  const myLng = sessionStorage.getItem('location.location_lng')
  if (myLat && myLng) {
    bounds.extend({
      lat: parseFloat(myLat),
      lng: parseFloat(myLng)
    })
    map.fitBounds(bounds)
  }

  new MarkerClusterer(map, markers, { imagePath: mapOptions.markerCluster.imagePath })

  initAutocomplete(map)
}

// advanced-filters
function searchbar () {
  function termField () {
    const term = document.getElementById('term')
    if (!term) {
      return false
    }
    const sidebarTerm = document.getElementById('sidebar-term')

    term.addEventListener('keydown', (e) => {
      sidebarTerm.value = e.target.value
    })
  }

  function dayFilter () {
    const day = document.getElementById('day')
    if (!day) {
      return
    }
    const timeWrapper = document.getElementById('filters-time')
    day.addEventListener('change', (e) => {
      if (e.target.value !== '') {
        if (!timeWrapper.classList.contains('is-opened')) {
          slideDownEl(timeWrapper)
        }
      } else {
        slideUpEl(timeWrapper)
      }
    })

    if (day.value !== '') {
      slideDownEl(timeWrapper)
    }
  }

  dayFilter()

  termField()
}

function isHidden (el) {
  return (el.offsetParent === null)
}

function slideDownEl (el) {
  Velocity(el, 'slideDown', {
    easing: 'easeOutQuart',
    duration: 400,
    complete: function () {
      el.classList.add('is-opened')
    }
  })
}

function slideUpEl (el) {
  Velocity(el, 'slideUp', {
    easing: 'easeOutQuart',
    duration: 400,
    complete: function () {
      el.classList.remove('is-opened')
    }
  })
}

function transitions () {
  Velocity(document.querySelectorAll('.services article'), 'transition.slideLeftIn', { stagger: 200 })
  Velocity(document.querySelectorAll('.categories .categories--item'), 'transition.slideLeftIn', { stagger: 100 })
}

function homepage () {
  const btnShowVideo = document.getElementById('show-video')
  if (btnShowVideo !== null) {
    const videoModal = document.getElementById('video-modal')
    let videoModalInstance = new bsn.Modal(videoModal)
    const videoSrc = btnShowVideo.href + '?modestbranding=1&rel=0&showinfo=0&html5=1&autoplay=1'
    videoModal.addEventListener('show.bs.modal', (e) => {
      document.getElementById('video-iframe').src = videoSrc
    })
    videoModal.addEventListener('hide.bs.modal', (e) => {
      document.getElementById('video-iframe').src = ''
    })

    btnShowVideo.addEventListener('click', (e) => {
      e.preventDefault()
      videoModalInstance.show()
    }, false)
  }
}

function init () {
  searchbar()

  transitions()

  userLocation.init()

  homepage()
}

document.onreadystatechange = function () {
  if (document.readyState === 'interactive') {
    init()
  }
}

window.infoWindow = infoWindow
window.setAddress = setAddress
window.initMap = initMap
window.initAutocomplete = initAutocomplete